import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import classes from "../styles/static.module.css"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <SEO title={frontmatter.title} />
      <div className="container">
        <section className={classes.staticArticle}>
          <header>
            <h1>{frontmatter.title}</h1>
            <time>Last Updated Date: {frontmatter.updated}</time>
          </header>
          <article dangerouslySetInnerHTML={{ __html: html }} />
        </section>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        updated(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
